<template>
    <v-content>
        <!--        <v-dialog v-model="dialogCreate" max-width="500px">-->
        <!--            <template v-slot:activator="{ on }">-->
        <!--                <v-btn color="primary" dark class="mb-2 float-right" v-on="on" :success="success" >Add Province</v-btn>-->
        <!--            </template>-->
        <!--            <createProvinceForm :success="success" @formResponse="onResponse" />-->
        <!--        </v-dialog>-->
        <v-expansion-panels multiple hover>
            <v-expansion-panel>
                <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-container fluid>
                        <div class="text-center">
                            <v-row align="start" justify="center">

                                <v-col class="d-flex mt-5" cols="12" sm="3">
                                    <v-autocomplete v-model="editedData.ReportType"
                                                    :items="reportTypes"
                                                    :label="$t('report_type')"
                                                    clearable
                                                    single-line
                                                    dense
                                                    outlined
                                                    item-text="value"
                                                    item-value="id"></v-autocomplete>
                                </v-col>

                                <v-col class="d-flex mt-5" cols="12" sm="2">
                                    <v-autocomplete v-model="editedData.ReportMode"
                                                    :items="['all','air','land']"
                                                    :label="$t('report_mode')"
                                                    clearable
                                                    dense
                                                    outlined
                                                    single-line
                                                    item-text="value"
                                                    item-value="value"></v-autocomplete>
                                </v-col>

                                <v-col class="d-flex mt-5" cols="12" sm="2">
                                    <v-autocomplete v-model="editedData.ArrivalDeparture"
                                                    :items="['Arrival','Departure']"
                                                    :label="$t('arrival_departure')"
                                                    clearable
                                                    dense
                                                    outlined
                                                    single-line
                                                    item-text="value"
                                                    item-value="value"></v-autocomplete>
                                </v-col>
                                <v-col class="d-flex mt-5" cols="12" sm="2" v-if="reportByYear || reportByMonth">
                                    <v-select :items="customYear"
                                              :label="$t('select_year')"
                                              dense
                                              outlined
                                              v-model="editedData.FromDate"
                                              prepend-inner-icon="event"></v-select>
                                </v-col>
                                <v-col class="d-flex mt-5" cols="12" sm="2" v-if="reportByMonth">
                                    <v-select :items="customMonth"
                                              :label="$t('select_month')"
                                              v-model="editedData.ToDate"
                                              prepend-inner-icon="event"
                                              item-text="value"
                                              item-value="id"
                                              dense
                                              outlined></v-select>
                                </v-col>
                                
                                <!--<v-col class="d-flex" cols="12" sm="2">
                                    <v-menu v-model="snackbar.menu1"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="editedData.FromDate"
                                                          :label="$t('from_date')"
                                                          clearable
                                                          prepend-icon="event"
                                                          readonly
                                                          v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="editedData.FromDate">

                                        </v-date-picker>
                                    </v-menu>
                                </v-col>-->

                                <v-col class="d-flex text-center" cols="12" sm="12">
                                    <v-spacer></v-spacer>
                                    <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                        <v-icon dark>search</v-icon>
                                        {{$t('search')}}
                                    </v-btn>
                                    <v-btn color="success mx-4" small @click="onDownload">
                                        <v-icon>get_app</v-icon>
                                        {{$t('download_to_excel')}}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-col>
                            </v-row>
                        </div>
                    </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>



        <vue-good-table :columns="columns"
                        :isLoading.sync="isLoading"
                        :rows="rows"
                        style="z-index:1"
                                max-height="400px"
                                :fixed-header="true"
                        :totalRows="totalRecords"
                        :sort-options="{
    enabled: false,
  }"
                        @on-column-filter="onColumnFilter"
                        @on-page-change="onPageChange"
                        @on-per-page-change="onPerPageChange"
                        @on-sort-change="onSortChange"
                        mode="remote">
            <template slot="table-column" slot-scope="props">
                <span>
                    {{ $t(props.column.label) }}
                </span>
            </template>
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                    <v-icon @click="editItem(props.row)" class="mr-2" small>edit</v-icon>
                </span>
                <span v-else>{{props.formattedRow[props.column.field]}}</span>
            </template>
            <div class="d-flex justify-center" v-if="isLoading">
                <v-progress-circular :size="50"
                                     color="primary"
                                     indeterminate></v-progress-circular>

            </div>
        </vue-good-table>
        <v-row class="mt-7">
            <div class="col-12" style="color: gray; text-align: right">
                {{$t('source')}} : {{$t('department_of_immigration')}}
            </div>
        </v-row>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "List",
        computed: {
            customYear() {
                let year = []
                for (let i = 0; i < 99; i++) {
                    year.push(`20${i < 10 ? '0' : ''}${i}`)
                }
                return year
            },
            formTitle() {
                return this.editedIndex === -1 ? "New User" : "Edit User";
            },
            reportByYear() {
                return this.editedData.ReportType === 'yearwise';
            },
            reportByMonth() {
                return this.editedData.ReportType === 'monthwise';
            },
            ministryIDErrors() {
                const errors = [];
                if (!this.$v.editedData.ministryID.$dirty) return errors;
                !this.$v.editedData.ministryID.required &&
                errors.push("First Name is required.");
                return errors;
            },
            provinceNameErrors() {
                const errors = [];
                if (!this.$v.editedData.provinceName.$dirty) return errors;
                !this.$v.editedData.provinceName.required &&
                errors.push("Last Name is required.");
                return errors;
            },
            items() {
                return [
                    {
                        text: this.$t('report'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('tourist_arrival_report_menu'),
                        exact: true,
                        to: '/reportModules/reportTourist-Arrival'
                    },
                    {
                        text: this.$t('national_international_report'),
                        disabled: true,
                        exact: true
                    }
                ]
            }
        },
        watch: {
            dialog(val) {
                val || this.close();
            }
        },
        components: {},
        data() {
            return {
                success: false,
                dialogCreate: false,
                dialogEdit: false,
                popout: true,
                isLoading: false,
                permissionData: {},
                columns: [
                    {
                        label: 'immigration_office',
                        field: "immigration_Office"

                    },
                    {
                        label: 'foreigner',
                        field: "foreigner"
                    },
                ],
                reportTypes: [
                    { id: 'yearwise', value: this.$t('year_wise') },
                    { id: 'monthwise', value: this.$t('month_wise') },
                ],
                customMonth: [

                    {
                        id: '01',
                        value: 'january'
                    },
                    {
                        id: '02',
                        value: 'february'
                    }, {
                        id: '03',
                        value: 'march'
                    }, {
                        id: '04',
                        value: 'april'
                    }, {
                        id: '05',
                        value: 'may'
                    }, {
                        id: '06',
                        value: 'june'
                    }, {
                        id: '07',
                        value: 'july'
                    }, {
                        id: '08',
                        value: 'august'
                    }, {
                        id: '09',
                        value: 'september'
                    }, {
                        id: '10',
                        value: 'october'
                    }, {
                        id: '11',
                        value: 'november'
                    }, {
                        id: '12',
                        value: 'december'
                    },
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "Immigration Office",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                editedIndex: -1,
                editedData: {
                    ReportType: "",
                    ReportMode: "",
                    ArrivalDeparture: "",
                    FromDate: "",

                },

                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                    /* breadcrumbsItem: [
                         {
                             text: 'Dashboard',
                             disabled: false,
                             to: 'MountaineeringDashboard',
                             exact: true

                         },
                         {
                             text: 'Mountaineering History Information',
                             disabled: true,
                         },
                     ],*/
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            };
        },
        mounted() {
            this.loadItems();
            this.addColumn();
        },
        methods: {
            onResponse(data) {
                console.log("yets" + data);
                if (data == true) {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.loadItems();
                }
            },
            editItem(props) {
                this.editedData.provinceId = props.provinceID;
                this.dialogEdit = true;
            },

            deleteItem(item) {
                const index = this.user.indexOf(item);
                confirm("Are you sure you want to delete this item?") &&
                this.user.splice(index, 1);
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            searchInput() {
                this.loadItems();
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },


            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            // load items is what brings back the rows from server
            addColumn() {
                this.permissionData = JSON.parse(localStorage.getItem("tokenData"));
                if (this.permissionData.Province_W) {
                    this.columns.push({
                        label: "Actions",
                        field: "actions",
                        sortable: false
                    });
                }
            },

            async loadItems() {
                if (this.reportByYear) {
                    this.isLoading = true;
                    axios.post("Report/GetArrivalByNationalityReportListAsyc", this.editedData)
                        .then(response => {
                            this.rows = response.data;
                            this.totalRecords = response.data.totalCount;
                            console.log(this.rows)
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });
                }
                else {
                    this.isLoading = true;
                    axios.post("Report/GetArrivalByNationalityReportListAsyc", this.editedData)
                        .then(response => {
                            this.rows = response.data;
                            this.totalRecords = response.data.totalCount;
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });
                }


            },
            async onDownload() {
                if (this.reportByYear) {
                    this.isLoading = true;
                    await axios({
                        url: 'Report/DownloadArrivalByNationalityReport',
                        method: 'POST',
                        responseType: 'blob',
                        data: this.editedData
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'immigration-report-file.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.isLoading = false;
                    });
                }
                else {
                    this.isLoading = true;
                    await axios({
                        url: 'Report/DownloadArrivalByNationalityMonthlyReport',
                        method: 'POST',
                        responseType: 'blob',
                        data: this.editedData
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'immigration-report-file.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.isLoading = false;
                    });
                }

            },
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }
</style>
